import { createRouter, createWebHistory } from "vue-router";

import Home from "../views/Home.vue";
import Product from "../views/Product.vue";
import Category from "../views/Category.vue";
import Search from "../views/Search.vue";
import Cart from "../views/Cart.vue";
import GiftCard from "../views/GiftCard.vue";
import Magasin from "../components/Pages/Magasin.vue";
import Domicile from "../components/Pages/Domicile.vue";
import Entreprise from "../components/Pages/Entreprise.vue";
import QuiSommesNous from "../components/Pages/QuiSommesNous.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/:category_slug/:product_slug",
    name: "Product",
    component: Product,
  },
  {
    path: "/:category_slug/",
    name: "Category",
    component: Category,
  },
  {
    path: "/search",
    name: "Search",
    component: Search,
  },
  {
    path: "/cart",
    name: "Cart",
    component: Cart,
  },
  {
    path: "/giftcard",
    name: "GiftCard",
    component: GiftCard,
  },
  {
    path: "/magasin",
    name: "Magasin",
    component: Magasin,
  },
  {
    path: "/domicile",
    name: "Domicile",
    component: Domicile,
  },
  {
    path: "/entreprise",
    name: "Entreprise",
    component: Entreprise,
  },
  {
    path: "/qui-sommes-nous",
    name: "Qui sommes nous",
    component: QuiSommesNous,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
