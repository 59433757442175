<template @event-open-modal="handleEvent">
  <n-card
    v-bind:title="'Inscription - ' + eventInfo.name"
    closable
    @close="handleClose"
    class="modal-card"
  >
    <div>
      <n-space vertical>
        <n-form :model="formValue" :rules="rules" size="medium" ref="formRef">
          <n-form-item label="Nom Prénom" path="user.name">
            <n-input
              v-model:value="formValue.user.name"
              placeholder="Nom prenom"
            />
          </n-form-item>
          <n-form-item label="Téléphone" path="user.phone">
            <n-input
              placeholder="06.01.02.03.04"
              v-model:value="formValue.user.phone"
            />
          </n-form-item>
          <n-form-item label="Email" path="user.email">
            <n-input
              placeholder="adresse@email.com"
              v-model:value="formValue.user.email"
            />
          </n-form-item>
        </n-form>
        <div class="button-container">
          <n-button @click="submitInscription">S'inscrire</n-button>
        </div>
      </n-space>
    </div>
  </n-card>
</template>

<script>
import axios from "axios";
import { ref } from "@vue/reactivity";
import { errorToast } from "../utils/error";
import { sendEmail } from "../utils/email";
import { toast } from 'bulma-toast';

export default {
  name: "InscriptionForm",
  props: {
    initialItem: Object,
    slug: String,
  },
  data() {
    return {
      eventInfo: {},
    };
  },
  mounted() {
    this.getEventInfo(this.slug);
  },
  setup() {
    const formRef = ref(null);

    return {
      formRef,
      formValue: ref({
        user: {
          name: "",
          phone: "",
          email: "",
        },
      }),
      rules: {
        user: {
          name: {
            type: "string",
            required: true,
            message: "Merci d'indiquer votre nom",
            trigger: "blur",
          },
          phone: {
            required: true,
            validator: (rule, value, callback) => {
              if (
                !value.match(
                  /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/gim
                )
              ) {
                callback(
                  new Error("Merci d'indiquer un numéro de téléphone valide")
                );
              } else {
                callback();
              }
            },
            message: "Merci d'indiquer votre numéro",
            trigger: ["input"],
          },
          email: {
            required: true,
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error("Merci d'indiquer votre email"));
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
              ) {
                callback(new Error("Merci d'indiquer un email valide"));
              } else {
                callback();
              }
            },
            message: "Merci d'indiquer votre e-mail",
            trigger: ["blur"],
          },
        },
      },
    };
  },
  methods: {
    async getEventInfo(slug) {
      await axios
        .get(`/api/v1/event/degustation/${slug}`)
        .then((response) => {
          this.eventInfo = response.data;
          if (this.eventInfo.inscrit >= this.eventInfo.place) {
            toast({
              message: "Désolé, il n'y a plus de place pour cet événement",
              type: "is-warning",
              dismissible: true,
              pauseOnHover: true,
              duration: 4000,
              position: "bottom-right",
            });
            this.$router.go();
          }
        })
        .catch((error) => {
          console.error(error);
          errorToast();
        });
    },
    handleClose() {
      this.$emit("event-close-modal", true);
    },
    submitInscription(event) {
      this.handleValidation(event);
    },
    handleValidation(e) {
      const formRefs = ref(this.formRef);
      e.preventDefault();
      formRefs.value.validate((errors) => {
        if (!errors) {
          this.registerEvent(this.formValue);
        } else {
          console.log(errors);
        }
      });
    },
    async registerEvent(form) {
      let data = { event: this.eventInfo.slug, ...form };
      let headers = {'X-CSRFTOKEN': this.$cookies.get('csrftoken')}
      axios
        .post("/api/v1/event/inscription", data, {headers: headers})
        .then(() => {
          sendEmail("template_su6k1yg", {
            email: data.user.email,
            name: data.user.name,
            event: this.eventInfo.name,
            date: this.eventInfo.date,
          });
          sendEmail("template_bflfhb8", {
            date: this.eventInfo.date,
            event: this.eventInfo.name,
            name: data.user.name,
            email: data.user.email,
            numero: data.user.phone,
          });
          this.$emit("event-close-modal", true);
        })
        .catch((err) => {
          console.log(err);
          errorToast();
        });
    },
  },
};
</script>

<style lang="scss">
.button_color {
  border-radius: 20%;
  width: 20px;
  height: 20px;
  text-align: center;
}
.modal-card {
  min-width: 350px;
}
.button-container {
  display: flex;
  justify-content: flex-end;
}
</style>
