<template>
  <div class="page-cart">
    <div class="columns is-multiline">
      <div class="column is-12">
        <h1 class="title">Panier</h1>
      </div>

      <div class="column is-12 box">
        <table class="table is-fullwidth" v-if="cartTotalLength">
          <thead>
            <tr>
              <th>Produit</th>
              <th>Prix</th>
              <th>Quantité</th>
              <th>Total</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            <CartItem
              v-for="item in cart.items"
              v-bind:key="item.product.id"
              v-bind:initialItem="item"
              v-on:removeFromCart="removeFromCart"
            />
          </tbody>
        </table>

        <p v-else>Vous n'avez pas encore de produits dans votre panier !</p>
      </div>

      <div class="column is-12 box">
        <h2 class="subtitle">Résumé</h2>

        <strong>{{ cartTotalPrice.toFixed(2) }}€</strong>,
        {{ cartTotalLength }} produits

        <hr />
        <n-checkbox v-model:checked="gifted"
          >Option emballage cadeau (+3€)</n-checkbox
        >
        <hr />
        <n-button @click="showModalCommand = true" v-show="cartTotalLength != 0"
          >Commander</n-button
        >
        <n-modal style="width: fit-content" v-model:show="showModalCommand">
          <contact-form
            @event-close-modal="successCommand"
            :gifted="gifted"
            :currentPrice="cartTotalPrice"
          ></contact-form>
        </n-modal>
      </div>
    </div>
  </div>
</template>

<script>
import CartItem from "@/components/CartItem.vue";
import ContactForm from "@/components/ContactForm.vue";
import { ref } from "vue";

export default {
  name: "Cart",
  components: {
    CartItem,
    ContactForm,
  },
  data() {
    return {
      cart: {
        items: [],
      },
      showModalCommand: ref(false),
      gifted: ref(false),
    };
  },
  mounted() {
    this.cart = this.$store.state.cart;
    this.empty = this.cart.items.length == 0;
  },
  methods: {
    removeFromCart(item) {
      this.cart.items = this.cart.items.filter(
        (i) => i.product.id !== item.product.id
      );
    },
    successCommand(event) {
      if (event == true) {
        this.showModalCommand = false;
      }
    },
  },
  computed: {
    cartTotalLength() {
      return this.cart.items.reduce((acc, curVal) => {
        return (acc += curVal.quantity);
      }, 0);
    },
    cartTotalPrice() {
      let total = this.cart.items.reduce((acc, curVal) => {
        return (acc += curVal.product.price * curVal.quantity);
      }, 0);
      total += 3;
      return this.gifted ? total : total - 3;
    },
  },
};
</script>
