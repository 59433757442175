<template>
  <div class="main-container">
    <h1 class="title">Qui sommes-nous ?</h1>
    <video id="myVideo" controls autoplay class="video-container">
      <source :src="require('@/assets/video.mp4')" type="video/mp4" />
    </video>
  </div>
</template>

<script>
export default {
  components: {},
  mounted() {
    var vid = document.getElementById("myVideo");
    vid.volume = 0.2;
    window.scrollTo(0, 0);
  },
  methods: {},
  computed: {},
};
</script>
<style lang="scss">
.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -40px;

  .title {
    align-self: flex-start;
  }

  .video-container {
    width: 80%;
  }
}
</style>
