<template>
  <!-- DEBUT DU PANNEAU DE RECHERCHE -->
  <div class="column is-12 sign-effect">
    <!-- TITRE -->
    <h3 class="searchTitle">Vous cherchez quelque chose ?</h3>

    <form action="javascript:void(0);" v-on:change="filterResults()">
      <!-- BARRE DE RECHERCHE -->
      <div class="searchbar">
        <label for="nom"> Nom de l'article : </label>
        <input
          id="nom"
          class="input fullWidth"
          type="text"
          placeholder="Bordeaux, Rosé..."
          v-model="vin"
        />
      </div>

      <!-- TYPE | PRIX -->
      <div class="othersForm">
        <!-- TYPE -->
        <div class="customInput">
          <label for="type">Famille de spiritueux : </label>
          <div class="select fullSize">
            <select id="type" v-model="typeSelected">
              <option></option>

              <option
                v-for="type in types"
                v-bind:value="type.id"
                v-bind:key="type.id"
              >
                {{ type.name }}
              </option>
            </select>
          </div>
        </div>

        <!-- PRIX -->
        <div class="customInput">
          <label for="prix">Prix compris entre : </label>

          <div id="prix">
            <input class="input" type="number" v-model="prix[0]" />
            <input class="input" type="number" v-model="prix[1]" />
          </div>
        </div>
      </div>
    </form>
  </div>
  <!-- FIN DU PANNEAU DE RECHERCHE -->
  <div v-if="typeSelected != '' || regionSelected != ''">
    <div style="display: flex; flex-direction: column">
      <h2 class="title">Résultat</h2>
      <div class="card-container">
        <ProductBox
          v-for="product in research.products"
          v-bind:key="product.id"
          v-bind:product="product"
        />
      </div>
    </div>
  </div>
  <div v-else>
    <h2 class="title">Tous nos spiritueux</h2>
    <div style="display: flex; flex-direction: column">
      <div class="card-container">
        <ProductBox
          v-for="product in research.products"
          v-bind:key="product.id"
          v-bind:product="product"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ProductBox from "../ProductBox.vue";
import axios from "axios";
import { toast } from "bulma-toast";

export default {
  components: { ProductBox },
  data() {
    return {
      category: {
        products: [],
      },
      research: {
        products: [],
      },
      vin: "",
      regionSelected: "",
      regions: {},
      page: "",
      prix: {},
      types: [
        {
          id: 4,
          name: "Whisky",
        },
        {
          id: 5,
          name: "Vodka",
        },
        {
          id: 6,
          name: "Rhum",
        },
        {
          id: 33,
          name: "Gin",
        },
        {
          id: 32,
          name: "Armagnac",
        },
        {
          id: 31,
          name: "Cognac",
        },
      ],
      typeSelected: "",
    };
  },
  mounted() {
    this.getCategory();
    window.scrollTo(0, 0);
  },
  watch: {
    $route(to) {
      if (to.name === "Category") {
        this.getCategory();
      }
    },
  },
  methods: {
    setPrix() {
      let prixMax = 0;
      this.category.products.forEach((product) => {
        if (product.price > prixMax) prixMax = Math.ceil(product.price);
      });
      this.prix = [0, prixMax];
    },
    async getCategory() {
      const categorySlug = this.$route.params.category_slug;
      this.page = categorySlug;
      this.$store.commit("setIsLoading", true);
      axios
        .get(`/api/v1/products/${categorySlug}/`)
        .then((response) => {
          this.category = { ...response.data };
          this.research = { ...response.data };
          document.title = this.category.name + " | Le Vin Vivant";
          this.setPrix();
        })
        .catch((error) => {
          console.error(error);

          toast({
            message: "Oh, on vient d'avoir une erreur. Merci de réessayer !",
            type: "is-danger",
            dismissible: true,
            pauseOnHover: true,
            duration: 4000,
            position: "bottom-right",
          });
        });
      this.$store.commit("setIsLoading", false);
    },
    filterResults() {
      this.research = { ...this.category };
      if (this.regionSelected)
        this.research.products = this.research.products.filter(
          (product) => this.regionSelected === product.region
        );
      if (this.typeSelected)
        this.research.products = this.research.products.filter((product) =>
          product.types.includes(this.typeSelected)
        );
      this.research.products = this.research.products.filter(
        (product) =>
          product.name.toLowerCase().includes(this.vin.toLowerCase()) &&
          product.price >= this.prix[0] &&
          product.price <= this.prix[1]
      );
    },
  },
};
</script>

<style lang="scss">
.card-container {
  display: flex;
  flex-wrap: wrap;
}
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  padding: 3px 10px 10px 10px;
  margin: 10px;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.customInput {
  width: 100% !important;
}

#prix {
  display: flex;
  flex-wrap: nowrap;
}

#region,
#type {
  width: 100%;
}

.fullWidth {
  width: 100% !important;
}

.fullSize {
  width: 90%;
}

.searchTitle {
  padding-left: 50px;
  padding-right: 50px;
  font-size: 2rem;
  margin-bottom: 20px;
  font-family: "Bebas Neue";
}

.othersForm {
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.page-category {
  min-height: 100vh;
  font-family: "Bangers";
}
.title {
  width: 25%;
  padding-top: 10px;
  font-family: "Bebas Neue";
}
@media screen and (max-width: 770px) {
  .othersForm {
    flex-direction: column;
  }
  .fullSize {
    width: 100%;
  }
  .title {
    width: 100%;
  }
  .card-container {
    flex-wrap: nowrap;
    flex-direction: column;
  }
  .card {
    margin-bottom: 2%;
  }
}

pre {
  background: #edeff5;
  padding: 20px;
}
</style>
