<template>
  <div class="columns" style="justify-content: center">
    <div class="column is-two-fifths">
      <img class="image picture" src="../assets/card_cad.jpg" />
    </div>
    <div class="column is-two-fifths right-side">
      <n-space vertical>
        <h1 class="main-title is-1">Carte Cadeau</h1>
        <p class="subtitle is-5 mt-3 mb-6">
          Un petit cadeau pour un quelqu'un d'important ?
        </p>
        <n-slider v-model:value="value" :step="5" :min="15" :max="500" />
        <div class="is-flex is-justify-content-space-between">
          <n-space align="center">
            <n-input-number
              size="large"
              v-model:value="value"
              :min="15"
              :max="500"
              :step="5"
              style="width: fit"
            />
            €
          </n-space>
          <button @click="addToCart()" class="command-btn">
            Ajouter au panier !
          </button>
        </div>
      </n-space>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { toast } from "bulma-toast";

export default {
  name: "GiftCard",
  setup() {
    return {
      value: ref(15),
    };
  },
  mounted() {
    document.title = "Cartes Cadeaux" + " | Le Vin Vivant";
    window.scrollTo(0, 0);
  },
  methods: {
    addToCart() {
      const item = {
        product: {
          alcool_degree: 0,
          capacity: 0,
          description: "",
          get_absolute_url: "",
          get_image: "",
          get_thumbnail: "",
          in_stock: true,
          id: this.value * (Math.floor(Math.random() * (25 - 2 + 1)) + 2),
          name: "Carte Cadeau de " + this.value + "€",
          price: this.value,
          region: null,
        },
        quantity: 1,
      };
      this.$store.commit("addToCart", item);
      toast({
        message: "Le produit a été ajouté au panier !",
        type: "is-success",
        dismissible: true,
        pauseOnHover: true,
        duration: 4000,
        position: "bottom-right",
      });
    },
  },
};
</script>

<style>
.picture {
  background-size: cover;
  max-width: 70%;
  max-height: 100%;
  border-radius: 20px;
  box-shadow: 0.1px 0.1px 2.2px rgba(0, 0, 0, 0.02),
    -0.5px -0.5px 5.3px rgba(0, 0, 0, 0.028),
    -1.8px -1.8px 10px rgba(0, 0, 0, 0.035),
    -3.1px -3.1px 17.9px rgba(0, 0, 0, 0.042),
    -1.9px -1.9px 33.4px rgba(0, 0, 0, 0.05),
    100px 100px 80px rgba(0, 0, 0, 0.07);
}

.right-side {
  margin-left: 30px;
}
.main-title {
  font-size: 35px;
  font-family: "Bebas Neue";
}

.command-btn {
  background-color: #383e42;
  padding: 5px 40px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
  cursor: pointer;
  border-radius: 10px;
  border: 2px dashed #383e42;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: 0.4s;
}

.command-btn span:last-child {
  display: none;
}

.command-btn:hover {
  transition: 0.4s;
  border: 2px dashed #383e42;
  background-color: #fff;
  color: #383e42;
}

.command-btn:active {
  background-color: #383e42;
}
</style>
