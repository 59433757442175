import { createApp } from "vue";
import App from "./App.vue";
import Animate from "animate.css";
import router from "./router";
import store from "./store";
import axios from "axios";
import naive from "naive-ui";
import { SetupCalendar } from "v-calendar";
import VueCookies from "vue-cookies";

// axios.defaults.baseURL = "http://localhost:8000/";
axios.defaults.baseURL = "https://levinvivant.com/";

createApp(App)
  .use(store)
  .use(router, axios)
  .use(Animate)
  .use(naive)
  .use(VueCookies)
  .use(SetupCalendar, {})
  .mount("#app");
require("@/assets/main.scss");
