<template>
  <div class="home">
    <!-- Banner -->
    <h1 class="home_h1">Bienvenue au Vin Vivant !</h1>
    <section
      class="hero is-medium is-dark mb-6 rounded_corner bg-section-banner"
    >
      <div class="hero-body"></div>

      <!-- Text under banner -->
    </section>
    <div class="has-text-centered">
      <h2 class="is-size-4 has-text-left title_font sep-tmp sketchy">
        Toute notre boutique !
      </h2>
    </div>
    <section class="subcategories-buttons">
      <router-link to="/vins" class="cardCustom vin">
        <p class="cardText">Vin</p>
      </router-link>
      <router-link to="/bieres" class="cardCustom biere">
        <p class="cardText">Bières</p>
      </router-link>
      <router-link to="/spiritueux" class="cardCustom soft">
        <p class="cardText">Spiritueux</p>
      </router-link>
      <router-link to="/epicerie" class="cardCustom apero">
        <p class="cardText">Epicerie</p>
      </router-link>
      <router-link to="/qui-sommes-nous" class="cardCustom week">
        <p class="cardText">Qui sommes-nous</p>
      </router-link>
      <router-link to="/giftcard" class="cardCustom giftcard">
        <p class="cardText">Carte Cadeau</p>
      </router-link>
    </section>
    <br />
    <br />
    <div class="has-text-centered">
      <h2 class="is-size-4 has-text-lest title_font sketchy">
        Nos événements !
      </h2>
    </div>
    <section class="subcategories-buttons">
      <!-- 3 new cards 16/02/2021 -->
      <router-link to="/domicile" class="cardCustom domicile">
        <p class="cardText">Dégustation à Domicile</p>
      </router-link>
      <router-link to="/magasin" class="cardCustom magasin">
        <p class="cardText">Soirée Dégustation en magasin</p>
      </router-link>
      <router-link to="/entreprise" class="cardCustom entreprise">
        <p class="cardText">Dégustation en entreprise</p>
      </router-link>
    </section>
    <br />
    <br />
    <section class="res"></section>
    <div class="columns is-multiline">
      <div class="column is-12 has-text-centered">
        <h2 class="is-size-4 has-text-lest title_font sketchy">
          Nos derniers produits !
        </h2>
      </div>
      <br />
      <br />

      <ProductBox
        v-for="product in latestProducts"
        v-bind:key="product.id"
        v-bind:product="product"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ProductBox from "@/components/ProductBox.vue";

export default {
  name: "Home",
  data() {
    return {
      latestProducts: [],
      allProducts: [],
    };
  },
  components: {
    ProductBox,
  },
  mounted() {
    this.getLatestProducts();
    this.getAllProducts();
    document.title = "Le Vin Vivant";
  },
  methods: {
    async getLatestProducts() {
      this.$store.commit("setIsLoading", true);

      await axios
        .get("api/v1/latest-products/")
        .then((response) => {
          this.latestProducts = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    async getAllProducts() {
      this.$store.commit("setIsLoading", true);

      await axios
        .get("api/v1/all-products/")
        .then((response) => {
          this.allProducts = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    extractCategoryName(url_str) {
      var tmp = url_str.substring(1);
      return tmp
        .substring(0, tmp.indexOf("/"))
        .replace(/^\w/, (c) => c.toUpperCase());
    },
  },
  method: {
    getCatValue() {
      return this.value;
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Bangers&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bangers&display=swap");
@import url("https://fonts.googleapis.com/css?family=Poppins:700");

@media only screen and (max-width: 428px) and (min-width: 200px) {
  .bg-section-banner {
    background-image: linear-gradient(
        rgba(0, 0, 0, 0.247),
        rgba(0, 0, 0, 0.226)
      ),
      url("../assets/adresses-mail1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 45% 10%;
    height: 500px;

    box-shadow: 2.8px 2.8px 2.2px rgba(0, 0, 0, 0.02),
      6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
      12.5px 12.5px 10px rgba(0, 0, 0, 0.035),
      22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
      41.8px 41.8px 33.4px rgba(0, 0, 0, 0.05),
      100px 100px 80px rgba(0, 0, 0, 0.07);
  }
}

@media only screen and (max-width: 900px) and (min-width: 600px) {
  .bg-section-banner {
    background-image: linear-gradient(
        rgba(0, 0, 0, 0.247),
        rgba(0, 0, 0, 0.226)
      ),
      url("../assets/adresses-mail1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 40% 10%;
    height: 500px;

    box-shadow: 2.8px 2.8px 2.2px rgba(0, 0, 0, 0.02),
      6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
      12.5px 12.5px 10px rgba(0, 0, 0, 0.035),
      22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
      41.8px 41.8px 33.4px rgba(0, 0, 0, 0.05),
      100px 100px 80px rgba(0, 0, 0, 0.07);
  }
}

.bg-section-banner {
  background-image: linear-gradient(rgba(0, 0, 0, 0.247), rgba(0, 0, 0, 0.226)),
    url("../assets/adresses-mail1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  // background-position: 100% 30%;
  height: 500px;

  box-shadow: 2.8px 2.8px 2.2px rgba(0, 0, 0, 0.02),
    6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
    12.5px 12.5px 10px rgba(0, 0, 0, 0.035),
    22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
    41.8px 41.8px 33.4px rgba(0, 0, 0, 0.05),
    100px 100px 80px rgba(0, 0, 0, 0.07);
}
.home_h1 {
  color: black;
  font-size: 6vmin;
  line-height: 1;
  font-weight: bold;
  letter-spacing: 2px;
  font-family: "Bebas Neue", sans-serif;
  text-transform: uppercase;
  padding-left: 40px;
  text-align: center;
}

.home-span {
  color: #7cfc00;
  font-size: 6vmin;
  line-height: 1;
  font-weight: bold;
  letter-spacing: 2px;
  font-family: "Bebas Neue", sans-serif;
  text-transform: uppercase;
  // padding-left: 40px;
}
@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
  .home_h1 {
    color: black;
    font-size: 7vmin;
    line-height: 1;
    font-weight: bold;
    letter-spacing: 2px;
    font-family: "Bebas Neue", sans-serif;
    text-transform: uppercase;
    padding-left: 40px;
    text-align: center;
  }

  .home-span {
    color: #7cfc00;
    font-size: 7vmin;
    line-height: 1;
    font-weight: bold;
    letter-spacing: 2px;
    font-family: "Bebas Neue", sans-serif;
    text-transform: uppercase;
    // padding-left: 40px;
  }
}
@media (min-width: 1281px) {
  /* hi-res laptops and desktops */
  .home_h1 {
    color: black;
    font-size: 10vmin;
    line-height: 1;
    font-weight: bold;
    letter-spacing: 2px;
    font-family: "Bebas Neue", sans-serif;
    text-transform: uppercase;
    padding-left: 40px;
    text-align: center;
  }

  .home-span {
    color: #7cfc00;
    font-size: 10vmin;
    line-height: 1;
    font-weight: bold;
    letter-spacing: 2px;
    font-family: "Bebas Neue", sans-serif;
    text-transform: uppercase;
    // padding-left: 40px;
  }
}

.home_h1 {
  color: black;
  font-size: 10vmin;
  line-height: 1;
  font-weight: bold;
  letter-spacing: 2px;
  font-family: "Bebas Neue", sans-serif;
  text-transform: uppercase;
  padding-left: 40px;
  text-align: center;
}

.home-span {
  color: #7cfc00;
  font-size: 10vmin;
  line-height: 1;
  font-weight: bold;
  letter-spacing: 2px;
  font-family: "Bebas Neue", sans-serif;
  text-transform: uppercase;
  // padding-left: 40px;
}

.cardCustom {
  display: flex;
  justify-content: center;
  height: 15rem;
  width: 30%;
  border-radius: 5px;
  align-items: center;
  margin: 10px 0;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

@media screen and (max-width: 770px) {
  .cardCustom {
    width: 100%;
  }
}

.cardText {
  text-align: center;
  color: white;
  font-size: 2rem;
  padding: 0 20px;
  background-color: rgba(black, 0.3);
  border-radius: 5px;
  font-family: "Bebas Neue", sans-serif;
}

.cardCustom.giftcard {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../assets/card_cad.jpg");
  background-size: cover;
  background-position: center;
}
.cardCustom.apero {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../assets/epicerie.jpg");
  background-size: cover;
}

.cardCustom.soft {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../assets/card_spi.jpeg");
  background-position: center;
  background-size: cover;
}

.cardCustom.biere {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../assets/card_bie.jpg");
  background-position: center;
  background-size: cover;
}

.cardCustom.week {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../assets/dimanche.jpg");
  background-size: cover;
}

.cardCustom.vin {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../assets/card_vin.jpeg");
  background-size: cover;
}

.cardCustom.entreprise {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../assets/VV_deg_ent.jpg");
  background-position: center;
  background-size: cover;
}

.cardCustom.magasin {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../assets/VV_deg_mag.jpeg");

  background-size: cover;
}

.cardCustom.domicile {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../assets/VV_deg_dom.jpeg");
  background-size: cover;
}

.subcategories-buttons {
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
  flex-wrap: wrap;
}

.title_font {
  font-family: "Bebas Neue", sans-serif;
}

.subtitle {
  font-family: "Bebas Neue", sans-serif;
}

.sep-tmp {
  margin-bottom: 1%;
  margin-bottom: 0.5%;
}

.scrolling-wrapper-flexbox {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
}

.bg-section-banner {
  box-shadow: 2.8px 2.8px 2.2px rgba(0, 0, 0, 0.02),
    6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
    12.5px 12.5px 10px rgba(0, 0, 0, 0.035),
    22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
    41.8px 41.8px 33.4px rgba(0, 0, 0, 0.05),
    100px 100px 80px rgba(0, 0, 0, 0.07);
}

.hero-body {
  position: left;
}
</style>
