<template>
  <div class="columns is-vcentered">
    <div class="column is-two-fifths">
      <img src="../../assets/VV_deg_mag.jpeg" class="image picture" />
    </div>
    <div class="description column">
      <h1>
        Deux fois par mois nous vous proposons une soirée dégustation chez nous
        au Vin Vivant !
      </h1>
      <h1>Vins, fromages et bonne humeur au programme !</h1>
      <div style="padding-top: 20px">
        <Calendar
          :columns="$screens({ default: 1, lg: 2 })"
          :attributes="attributes"
          @dayclick="onDayClick"
        />
      </div>
      <div>
        <n-modal style="width: fit-content" v-model:show="showModalInscription">
          <inscriptionForm
            @event-close-modal="closeModal"
            v-bind:slug="slug"
          ></inscriptionForm>
        </n-modal>
      </div>
    </div>
  </div>
</template>

<script>
import "v-calendar/dist/style.css";

import InscriptionForm from "@/components/Inscription.vue";
import { Calendar } from "v-calendar";
import axios from "axios";
import { toast } from "bulma-toast";
import { ref } from "@vue/reactivity";

export default {
  components: {
    Calendar,
    InscriptionForm,
  },
  mounted() {
    this.getDates();
    window.scrollTo(0, 0);
  },
  data() {
    const degu = [];
    return {
      incId: degu.length,
      showModalInscription: ref(false),
      degu,
      slug: "",
    };
  },
  methods: {
    onDayClick(day) {
      if (
        day.popovers.length != 0 &&
        day.popovers[0].customData.remaining > 0
      ) {
        this.slug = day.popovers[0].customData.slug;
        this.showModalInscription = true;
      }
    },
    async getDates() {
      axios
        .get("/api/v1/event/degustation/")
        .then((response) => {
          for (let eventInfo of response.data) {
            this.degu.push({
              description: eventInfo.name,
              isComplete: false,
              remaining: eventInfo.place - eventInfo.inscrit,
              dates: new Date(eventInfo.date),
              slug: eventInfo.slug,
              highlight: {
                color:
                  eventInfo.place - eventInfo.inscrit <= 0 ? "red" : "purple",
                fillMode: "solid",
              },
            });
          }
        })
        .catch((error) => {
          console.error(error);

          toast({
            message: "Oh, on vient d'avoir une erreur. Merci de réessayer !",
            type: "is-danger",
            dismissible: true,
            pauseOnHover: true,
            duration: 4000,
            position: "bottom-right",
          });
        });
    },
    closeModal(event) {
      if (event == true) {
        this.showModalInscription = false;
      }
    },
  },
  computed: {
    attributes() {
      return [
        ...this.degu.map((degu) => ({
          dates: degu.dates,
          highlight: {
            color: degu.highlight.color,
            fillMode: "solid",
          },
          popover: {
            label: degu.description,
          },
          customData: degu,
        })),
      ];
    },
  },
};
</script>

<style>
.description {
  padding-left: 40px;
  font-size: 1.2em;
  text-align: center;
}
.picture {
  border-radius: 20px;
  box-shadow: 0.1px 0.1px 2.2px rgba(0, 0, 0, 0.02),
    -0.5px -0.5px 5.3px rgba(0, 0, 0, 0.028),
    -1.8px -1.8px 10px rgba(0, 0, 0, 0.035),
    -3.1px -3.1px 17.9px rgba(0, 0, 0, 0.042),
    -1.9px -1.9px 33.4px rgba(0, 0, 0, 0.05),
    100px 100px 80px rgba(0, 0, 0, 0.07);
  max-width: 90%;
  margin: 0 auto;
}
</style>
