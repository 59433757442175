<template>
  <div class="columns is-vcentered">
    <div class="column is-two-fifths">
      <img src="../../assets/VV_deg_dom.jpeg" class="image picture" />
    </div>
    <div class="description column">
      <h1>Votre caviste viens chez vous !</h1>
      <h1>
        Nous pouvons animer une dégustation de vins et de fromages (minimum 8
        personnes)!
      </h1>
      <h1>Pour tout renseignement il suffit de nous contacter par email</h1>
      <a href="mailto:cavelevinvivant@gmail.com">ici :)</a>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style>
.description {
  padding-left: 40px;
  font-size: 1.2em;
  text-align: center;
}
.picture {
  border-radius: 20px;
  box-shadow: 0.1px 0.1px 2.2px rgba(0, 0, 0, 0.02),
    -0.5px -0.5px 5.3px rgba(0, 0, 0, 0.028),
    -1.8px -1.8px 10px rgba(0, 0, 0, 0.035),
    -3.1px -3.1px 17.9px rgba(0, 0, 0, 0.042),
    -1.9px -1.9px 33.4px rgba(0, 0, 0, 0.05),
    100px 100px 80px rgba(0, 0, 0, 0.07);
  margin: 0 auto;
}
</style>
