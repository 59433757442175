<template>
  <Vins v-if="page == 'vins'" />
  <Bieres v-else-if="page == 'bieres'" />
  <Spiritueux v-else-if="page == 'spiritueux'" />
  <Epicerie v-else-if="page == 'epicerie'" />
</template>

<script>
import axios from "axios";
import { toast } from "bulma-toast";
import Vins from "@/components/Pages/Vins";
import Spiritueux from "@/components/Pages/Spiritueux";
import Epicerie from "@/components/Pages/Epicerie";
import Bieres from "@/components/Pages/Bieres";

export default {
  name: "Category",
  components: {
    Vins,
    Spiritueux,
    Epicerie,
    Bieres,
  },
  data() {
    return {
      category: {
        products: [],
      },
      research: {
        products: [],
      },
      vin: "",
      regionSelected: "",
      regions: {},
      page: "",
      prix: {},
      types: {},
      typeSelected: "",
    };
  },
  mounted() {
    this.getCategory();
    this.getRegions();
    this.getType();
  },
  watch: {
    $route(to) {
      if (to.name === "Category") {
        this.getCategory();
      }
    },
  },
  methods: {
    async getType() {
      axios
        .get("/api/v1/types")
        .then((res) => {
          this.types = { ...res };
        })
        .catch((error) => {
          console.error(error);
          toast({
            message: "Oh, on vient d'avoir une erreur. Merci de réessayer !",
            type: "is-danger",
            dismissible: true,
            pauseOnHover: true,
            duration: 4000,
            position: "bottom-right",
          });
        });
    },
    setPrix() {
      let prixMax = 0;
      this.category.products.forEach((product) => {
        if (product.price > prixMax) prixMax = Math.ceil(product.price);
      });
      this.prix = [0, prixMax];
    },
    async getRegions() {
      axios
        .get("/api/v1/regions")
        .then((res) => {
          this.regions = { ...res };
        })
        .catch((error) => {
          console.error(error);

          toast({
            message: "Oh, on vient d'avoir une erreur. Merci de réessayer !",
            type: "is-danger",
            dismissible: true,
            pauseOnHover: true,
            duration: 4000,
            position: "bottom-right",
          });
        });
    },
    async getCategory() {
      const categorySlug = this.$route.params.category_slug;
      this.page = categorySlug;
      this.$store.commit("setIsLoading", true);
      axios
        .get(`/api/v1/products/${categorySlug}/`)
        .then((response) => {
          this.category = { ...response.data };
          this.research = { ...response.data };
          document.title = this.category.name + " | Le Vin Vivant";
          this.setPrix();
        })
        .catch((error) => {
          console.error(error);

          toast({
            message: "Oh, on vient d'avoir une erreur. Merci de réessayer !",
            type: "is-danger",
            dismissible: true,
            pauseOnHover: true,
            duration: 4000,
            position: "bottom-right",
          });
        });
      this.$store.commit("setIsLoading", false);
    },
    filterResults() {
      this.research = { ...this.category };
      if (this.regionSelected)
        this.research.products = this.research.products.filter(
          (product) => this.regionSelected === product.region
        );
      if (this.typeSelected)
        this.research.products = this.research.products.filter((product) =>
          product.types.includes(this.typeSelected)
        );
      this.research.products = this.research.products.filter(
        (product) =>
          product.name.toLowerCase().includes(this.vin.toLowerCase()) &&
          product.price > this.prix[0] &&
          product.price < this.prix[1]
      );
    },
  },
};
</script>

<style lang="scss">
.customInput {
  width: 100% !important;
}

#prix {
  display: flex;
  flex-wrap: nowrap;
}

#region,
#type {
  width: 100%;
}

.fullWidth {
  width: 100% !important;
}

.fullSize {
  width: 90%;
}

.searchTitle {
  padding-left: 50px;
  font-size: 2rem;
}

.othersForm {
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.products {
  width: 100%;
  display: contents;
}

.page-category {
  min-height: 100vh;
  font-family: "Bebas Neue", sans-serif;
}

@media screen and (max-width: 770px) {
  .othersForm {
    flex-direction: column;
  }
  .fullSize {
    width: 100%;
  }
}

pre {
  background: #edeff5;
  padding: 20px;
}
</style>
