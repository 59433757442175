import { createStore } from "vuex";
import CryptoJS from "crypto-js";

const s_key = process.env.VUE_APP_SECRET_KEY;

export default createStore({
  state: {
    cart: {
      items: [],
    },
    isAuthenticated: false,
    token: "",
    isLoading: false,
  },
  mutations: {
    initializeStore(state) {
      if (localStorage.getItem("cart")) {
        let bytes = CryptoJS.AES.decrypt(localStorage.getItem("cart"), s_key);
        state.cart = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      } else {
        let encrypt = CryptoJS.AES.encrypt(JSON.stringify(state.cart), s_key);
        localStorage.setItem("cart", encrypt.toString());
      }
    },
    addToCart(state, item) {
      const exists = state.cart.items.filter(
        (i) => i.product.id == item.product.id
      );
      if (exists.length) {
        exists[0].quantity =
          parseInt(exists[0].quantity) + parseInt(item.quantity);
      } else {
        state.cart.items.push(item);
      }
      localStorage.setItem(
        "cart",
        CryptoJS.AES.encrypt(JSON.stringify(state.cart), s_key).toString()
      );
    },
    setIsLoading(state, status) {
      state.isLoading = status;
    },
  },
  actions: {},
  modules: {},
});
