<template>
  <tr>
    <td>
      <router-link :to="item.product.get_absolute_url">{{
        item.product.name
      }}</router-link>
    </td>
    <td>{{ item.product.price }}€</td>
    <td>
      <n-space :inline="true">
        {{ item.quantity }}
        <n-button
          class="button_color"
          size="tiny"
          @click="decrementQuantity(item)"
        >
          -
        </n-button>
        <n-button
          class="button_color"
          size="tiny"
          @click="incrementQuantity(item)"
        >
          +
        </n-button>
      </n-space>
    </td>
    <td>{{ getItemTotal(item).toFixed(2) }}€</td>
    <td><button class="delete" @click="removeFromCart(item)"></button></td>
  </tr>
</template>

<script>
import CryptoJS from "crypto-js";

const s_key = process.env.VUE_APP_SECRET_KEY;

export default {
  name: "CartItem",
  props: {
    initialItem: Object,
  },
  data() {
    return {
      item: this.initialItem,
    };
  },
  methods: {
    getItemTotal(item) {
      return item.quantity * item.product.price;
    },
    decrementQuantity(item) {
      item.quantity -= 1;
      if (item.quantity === 0) {
        this.$emit("removeFromCart", item);
      }
      this.updateCart();
    },
    incrementQuantity(item) {
      item.quantity += 1;
      this.updateCart();
    },
    updateCart() {
      let encrypt = CryptoJS.AES.encrypt(
        JSON.stringify(this.$store.state.cart),
        s_key
      );
      localStorage.setItem("cart", encrypt.toString());
    },
    removeFromCart(item) {
      this.$emit("removeFromCart", item);
      this.updateCart();
    },
  },
};
</script>

<style lang="scss">
.button_color {
  border-radius: 20%;
  width: 20px;
  height: 20px;
  text-align: center;
}
</style>
