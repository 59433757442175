<template>
  <div class="columns" style="justify-content: center">
    <div class="column is-two-fifths">
      <img v-bind:src="product.get_image" class="picture" object-fit="fill" />
    </div>
    <div class="column right-side">
      <n-space vertical>
        <p class="main-title">{{ product.name }}</p>
        <p class="title is-4 has-text-weight-medium ml-2">
          Prix: {{ product.price }}€
        </p>
        <h1 v-if="stock()" style="color: green">✔ En stock</h1>
        <h1 v-else style="color: red">✘ En rupture de stock</h1>

        <p class="subtitle is-5 mt-3 mb-6 ml-2" style="min-height: auto">
          {{ product.description }}
        </p>
        <n-space>
          <p class="subtitle is-6 ml-2">
            Degrée d'alcool : {{ product.alcool_degree }}%
          </p>
          <p class="subtitle is-6">Contenance : {{ product.capacity }}cl</p>
        </n-space>
        <n-space class="mt-4" :justify="start" :size="10">
          <n-input-number
            v-model:value="quantity"
            clearable
            :min="1"
            :placeholder="'Quantité'"
            :size="'large'"
            style="width: 100px"
            class="ml-2"
          />

          <a
            style="width: fit-content"
            class="button is-dark"
            @click="addToCart()"
            >Ajouter au panier</a
          >
        </n-space>
      </n-space>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { toast } from "bulma-toast";

export default {
  name: "Product",
  data() {
    return {
      product: {},
      quantity: 1,
    };
  },
  mounted() {
    this.getProduct();
    window.scrollTo(0, 0);
  },
  methods: {
    async getProduct() {
      this.$store.commit("setIsLoading", true);

      const category_slug = this.$route.params.category_slug;
      const product_slug = this.$route.params.product_slug;

      await axios
        .get(`/api/v1/products/${category_slug}/${product_slug}`)
        .then((response) => {
          this.product = response.data;
          document.title = this.product.name + " | Le Vin Vivant";
          axios.get(`/api/v1/types/`).then((res) => {
            let idType = response.data.types[0];
            let type = res.data.find((elem) => elem.id == idType);
            response.data.types[0] = type.name;
          });
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },

    addToCart() {
      if (isNaN(this.quantity) || this.quantity < 1) {
        this.quantity = 1;
      }
      const item = {
        product: this.product,
        quantity: this.quantity,
      };
      this.$store.commit("addToCart", item);

      toast({
        message: "Le produit a été ajouté au panier !",
        type: "is-success",
        dismissible: true,
        pauseOnHover: true,
        duration: 4000,
        position: "bottom-right",
      });
    },
    stock() {
      return this.product.in_stock === true;
    },
  },
};
</script>

<style>
.subtitle {
  font-family: "Bebas Neue";
}

.main-title {
  font-size: 35px;
  font-family: "Bebas Neue";
}

.picture {
  border-radius: 20px;
  box-shadow: 0.1px 0.1px 2.2px rgba(0, 0, 0, 0.02),
    -0.5px -0.5px 5.3px rgba(0, 0, 0, 0.028),
    -1.8px -1.8px 10px rgba(0, 0, 0, 0.035),
    -3.1px -3.1px 17.9px rgba(0, 0, 0, 0.042),
    -1.9px -1.9px 33.4px rgba(0, 0, 0, 0.05),
    100px 100px 80px rgba(0, 0, 0, 0.07);
}

.right-side {
  margin-left: 30px;
}
.title {
  margin-top: 20px;
}

.command-btn {
  background-color: #383e42;
  padding: 5px 40px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
  cursor: pointer;
  border-radius: 10px;
  border: 2px dashed #383e42;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: 0.4s;
}

.command-btn span:last-child {
  display: none;
}

.command-btn:hover {
  transition: 0.4s;
  border: 2px dashed #383e42;
  background-color: #fff;
  color: #383e42;
}

.command-btn:active {
  background-color: #383e42;
}
</style>
