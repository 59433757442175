import { toast } from "bulma-toast";

export const errorToast = () => {
  toast({
    message: "Oh, on vient d'avoir une erreur. Merci de réessayer !",
    type: "is-danger",
    dismissible: true,
    pauseOnHover: true,
    duration: 4000,
    position: "bottom-right",
  });
};
