<template>
  <!-- STEP LIST -->
  <n-card title="Commande" closable @close="handleClose">
    <n-space>
      <div style="margin-top: 20px">
        <n-steps vertical :current="current" :status="currentStatus">
          <n-step title="Coordonnées" />
          <n-step title="Méthode de récuperation" />
          <n-step title="Récapitulatif" />
        </n-steps>
      </div>
      <n-space vertical>
        <!-- STEP COORD -->
        <div v-show="current == 1">
          <n-space vertical>
            <n-form
              :model="formValueP1"
              :rules="rules"
              size="medium"
              ref="formRefP1"
            >
              <n-form-item label="Nom Prénom" path="user.name">
                <n-input
                  v-model:value="formValueP1.user.name"
                  placeholder="Nom prenom"
                />
              </n-form-item>
              <n-form-item label="Téléphone" path="user.phone">
                <n-input
                  placeholder="06.01.02.03.04"
                  v-model:value="formValueP1.user.phone"
                />
              </n-form-item>
              <n-form-item label="Email" path="user.email">
                <n-input
                  placeholder="adresse@email.com"
                  v-model:value="formValueP1.user.email"
                />
              </n-form-item>
            </n-form>
          </n-space>
        </div>

        <!-- STEP METHODE DE RECUP -->
        <div class="temp" v-show="current == 2">
          <n-form
            :model="formValueP2"
            :rules="rules"
            size="medium"
            ref="formRefP2"
          >
            <n-form-item label="Méthode de livraison">
              <n-radio-group v-model:value="value" name="radiogroup">
                <n-space vertical>
                  <n-radio
                    v-for="way in shipWay"
                    :key="way.value"
                    :value="way.value"
                    :disabled="
                      way.label === 'Livraison à domicile' && disabledShipping
                    "
                  >
                    {{ way.label }}</n-radio
                  >
                  <div class="condShipping">
                    * La livraison à domicile est disponible à partir de 60€
                    d'achat.
                  </div>
                </n-space>
              </n-radio-group>
            </n-form-item>
            <n-form-item
              v-show="value != 'shiphome'"
              label="Date de récupération"
              path="pickandcollect.pickTime"
            >
              <n-date-picker
                type="date"
                :format="dateFormat"
                v-model:value="formValueP2.pickandcollect.pickTime"
                :is-date-disabled="datePickDisabled"
              />
            </n-form-item>
            <div v-show="value == 'shiphome'">
              <n-form-item
                label="Adresse de livraison"
                path="shipping.shippingAddress"
              >
                <n-input
                  required
                  placeholder="1 rue François du Vin, Rennes"
                  v-model:value="formValueP2.shipping.shippingAddress"
                />
              </n-form-item>
              <n-form-item
                v-show="value == 'shiphome'"
                label="Date de livraison"
                path="shipping.shippingTime"
              >
                <n-date-picker
                  type="date"
                  :format="dateFormat"
                  v-model:value="formValueP2.shipping.shippingTime"
                  :is-date-disabled="dateDisabled"
                />
              </n-form-item>
            </div>
          </n-form>
        </div>

        <!-- STEP RECAP -->
        <div v-show="current == 3" style="margin: -30px 0 10px 0">
          <div class="is-size-4">Coordonnées</div>
          <p>Nom Prénom: {{ formValueP1.user.name }}</p>
          <p>Téléphone: {{ formValueP1.user.phone }}</p>
          <p>Email: {{ formValueP1.user.email }}</p>

          <div class="is-size-4" style="margin-top: 20px">
            Méthode de livraison:
          </div>
          <p>
            {{
              value == "pickcollect" ? "Pick & Collect" : "Livraison à domicile"
            }}
          </p>
          <div v-show="value != 'shiphome'">
            <p>
              Date de récupération préferée:
              <n-time
                :time="formValueP2.pickandcollect.pickTime"
                :format="dateFormat"
              />
            </p>
          </div>
          <div v-show="value == 'shiphome'">
            <p>
              Adresse de livraison: {{ formValueP2.shipping.shippingAddress }}
            </p>
            <p>
              Date de livraison préferée:
              <n-time
                :time="formValueP2.shipping.shippingTime"
                :format="dateFormat"
              />
            </p>
          </div>
        </div>

        <!-- BOTTOM BUTTONS -->
        <n-space>
          <div v-if="thankyou == false">
            <n-button v-if="current != 1" @click="navigationPrevious"
              >Précédent</n-button
            >
            <n-button v-if="current != 3" @click="navigationNext"
              >Suivant</n-button
            >
            <n-button v-else @click="sendEmail">Envoyer</n-button>
          </div>
          <div v-else>
            <h1>Merci pour votre commande !</h1>
            <n-button @click="handleClose">Fermer</n-button>
          </div>
        </n-space>
      </n-space>
    </n-space>
  </n-card>
</template>

<script>
import { defineComponent, ref } from "vue";
import emailjs from "emailjs-com";
import dayjs from "dayjs";

function setDefaultDate() {
  const now = new Date();
  const plusThree = dayjs(now).add(3, "day").toDate();
  return plusThree.getTime();
}

function setPickDefaultDate() {
  const now = new Date();
  return dayjs(now).add(1, "day").toDate().getTime();
}

export default defineComponent({
  setup() {
    const formRefP1 = ref(null);
    const formRefP2 = ref(null);
    const currentRef = ref(1);

    return {
      thankyou: ref(false),
      list_products: "",
      disabledShipping: ref(false),
      // Shipping Related
      shipWay: [
        {
          label: "Pick & Collect",
          value: "pickcollect",
        },
        {
          label: "Livraison à domicile",
          value: "shiphome",
        },
      ],
      value: ref("pickcollect"),
      dateFormat: "dd/MM/yyyy",

      // Step Related
      currentStatus: ref("process"),
      current: currentRef,
      direction: ref(null),
      next() {
        if (currentRef.value === null || currentRef.value === 1) {
          currentRef.value = 2;
        } else if (currentRef.value == 2) {
          currentRef.value = 3;
        }
      },
      prev() {
        if (currentRef.value === 0 || currentRef.value == null)
          currentRef.value = 1;
        else if (currentRef.value === 2) currentRef.value = 1;
        else if (currentRef.value === 3) currentRef.value = 2;
      },

      // Form related
      formRefP1,
      formValueP1: ref({
        user: {
          name: "",
          phone: "",
          email: "",
        },
      }),
      formRefP2,
      formValueP2: ref({
        shipping: {
          shippingAddress: "",
          shippingTime: setDefaultDate(),
        },
        pickandcollect: {
          pickTime: setPickDefaultDate(),
        },
      }),
      shippingMethods: ["Pick & Collect", "Livraison à domicile"].map((v) => ({
        label: v,
        value: v,
      })),
      rules: {
        user: {
          name: {
            type: "string",
            required: true,
            message: "Merci d'indiquer votre nom",
            trigger: "blur",
          },
          phone: {
            required: true,
            validator: (rule, value, callback) => {
              if (
                !value.match(
                  /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/gim
                )
              ) {
                callback(
                  new Error("Merci d'indiquer un numéro de téléphone valide")
                );
              } else {
                callback();
              }
            },
            message: "Merci d'indiquer votre numéro",
            trigger: ["input"],
          },
          email: {
            required: true,
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error("Merci d'indiquer votre email"));
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
              ) {
                callback(new Error("Merci d'indiquer un email valide"));
              } else {
                callback();
              }
            },
            message: "Merci d'indiquer votre e-mail",
            trigger: ["blur"],
          },
        },
        shipping: {
          shippingAddress: {
            required: true,
            message: "Merci d'indiquer votre adresse de livraison",
            trigger: ["input"],
          },
          shippingTime: {
            type: "number",
            required: true,
            trigger: [],
            message: "Merci d'indiquer une date de livraison préférée",
          },
        },
        pickandcollect: {
          pickTime: {
            type: "number",
            required: true,
            trigger: ["blur", "change"],
            message: "Merci d'indiquer une date de récupération préférée",
          },
        },
      },
    };
  },
  props: {
    gifted: Boolean,
    currentPrice: Number,
  },
  methods: {
    navigationNext(e) {
      this.direction = 1;
      if (this.current == 1) this.handleValidationPart1(e);
      if (this.current == 2) {
        if (this.value == "pickcollect") this.next();
        else this.handleValidationPart2(e);
      }
    },
    navigationPrevious() {
      this.direction = 2;
      this.prev();
    },
    handleValidationPart1(e) {
      const formRefs = ref(this.formRefP1);
      const direction = ref(this.direction);
      e.preventDefault();
      console.log(formRefs.value);
      formRefs.value.validate((errors) => {
        if (!errors) {
          direction.value == 1 ? this.next() : this.prev();
        } else {
          console.log(errors);
        }
      });
    },
    handleValidationPart2(e) {
      const formRefs = ref(this.formRefP2);
      const direction = ref(this.direction);
            console.log(formRefs.value);

      e.preventDefault();
      formRefs.value.validate((errors) => {
        if (!errors) {
          direction.value == 1 ? this.next() : this.prev();
        } else {
          console.log(errors);
        }
      });
    },
    dateDisabled(ts) {
      const minDate = new Date();
      minDate.setDate(minDate.getDate() + 1);
      return minDate > ts;
    },
    datePickDisabled(ts) {
      const minDate = new Date();
      minDate.setDate(minDate.getDate());
      return minDate > ts;
    },
    sendEmail() {
      var shippingDate;
      this.thankyou = true;
      var day = dayjs().format("DD-MM-YYYY").toString();
      var time = dayjs().format("HH:mm").toString();
      this.list_products = this.formatList(this.$store.state.cart);
      if (this.value == "pickcollect") {
        shippingDate = new Date(
          this.formValueP2.pickandcollect.pickTime
        ).toLocaleDateString();
      } else {
        shippingDate = new Date(
          this.formValueP2.shipping.shippingTime
        ).toLocaleDateString();
      }

      emailjs.send(
        "service_p25z9qa",
        "template_orz4fxr",
        {
          dayCmdCreated: day,
          timeCmdCreated: time,
          name: this.formValueP1.user.name,
          mail: this.formValueP1.user.email,
          list_products: this.list_products,
          telephone: this.formValueP1.user.phone,
          gifted: this.gifted == true ? "Oui" : "Non",
          recoveryMethod:
            this.value == "pickcollect"
              ? "Pick & Collect"
              : "Livraison à domicile",
          shippingAddress: this.formValueP2.shipping.shippingAddress,
          shippingTime: shippingDate,
          price: this.currentPrice.toFixed(2),
        },
        "4clwg177igxiZzql4"
      );
      emailjs.send(
        "service_p25z9qa",
        "template_e8paf8f",
        {
          mail: this.formValueP1.user.email,
          dayCmdCreated: day,
          name: this.formValueP1.user.name,
          list_product: this.list_products,
          recoveryMethod:
            this.value == "pickcollect"
              ? "Pick & Collect"
              : "Livraison à domicile",
          price: this.currentPrice.toFixed(2),
        },
        "4clwg177igxiZzql4"
      );
      localStorage.removeItem("cart");
    },
    handleClose() {
      this.$emit("event-close-modal", true);
      this.$router.go();
    },
    formatList(cart) {
      var clean_list = new Array();
      cart.items.forEach((element) => {
        console.log(element.product.types[0]);
        clean_list.push(
          element.quantity +
            "x" +
            " " +
            element.product.name +
            " - " +
            element.product.types[0]
        );
      });
      return clean_list.join(" || ");
    },
  },
  mounted() {
    this.disabledShipping = this.currentPrice < 60;
  },
});
</script>

<style>
.coordBlock {
  margin: 10px;
  padding: 10px;
}

.condShipping {
  font-size: 10px;
  font-style: italic;
  margin-top: 5px;
}
</style>
